import React, { useState } from "react";
import logo from "../../Images/mowgli-sanctuary-logo.png";
import nft from "../../Images/nft.jpg";
import pass from "../../Images/pass.png";
import x from "../../Images/x.png";
import insta from "../../Images/insta.png";
import telegram from "../../Images/telegram.png";
import video from "../../video/videobg.mp4";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const [tab, setTab] = useState("SocialPoints");
  const [taskTab, setTaskTab] = useState("AllTasks");

  return (
    <div>
      <header
        id="header"
        className="fixed-top bg-dark  "
        style={{ "--bs-bg-opacity": 0.5 }}
      >
        <div className="container d-flex align-items-center">
          <div className="col-sm-3">
            <a href="/" className="logo ">
              <img src={logo} alt="Mowgli Sanctuary" className="img-fluid" />
            </a>
          </div>
          <div className="col-sm-5"></div>
          <div className="col-sm-4">
            <div className="row">
              <div className="col-sm-6">
                <nav className="nav-menu d-none d-lg-block">
                  <ul>
                    <li>
                      <a href="#">Points</a>
                    </li>
                    <li>
                      <a href="#">Claim Airdrop</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-sm-6">
                <a href="#" className="get-started-btn scrollto">
                  @EWEWEVFO009
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="headermain">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="winbox">
                <h1>Win 100 Mowgli NFTs</h1>
                <h4>Write story and share it to social media accounts.</h4>
                <p>
                  Ten Winners Will Be Selected And Contacted Via Direct Message
                  On X On May 24th.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      <main id="main">
        <section className="about">
          <div className="container">
            <div className="section-title text-center">
              <h2>Participate And Earn $NFTs</h2>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="wrapper">
                  <div className="tab-wrapper">
                    <ul className="tabs">
                      <li
                        onClick={() => setTab("StoryPoints")}
                        className={
                          tab === "StoryPoints"
                            ? "tab-link active"
                            : "tab-link "
                        }
                        // data-tab="1"
                      >
                        Story Points
                      </li>
                      <li
                        onClick={() => setTab("SocialPoints")}
                        className={
                          tab === "SocialPoints"
                            ? "tab-link active"
                            : "tab-link "
                        }
                        // data-tab="2"
                      >
                        Social Points
                      </li>
                      <li
                        onClick={() => setTab("MowgliPass")}
                        className={
                          tab === "MowgliPass" ? "tab-link active" : "tab-link "
                        }
                        // data-tab="3"
                      >
                        Mowgli Pass
                      </li>
                    </ul>
                  </div>

                  <div className="content-wrapper">
                    {/* <!-- tab-1 start --> */}
                    {tab === "StoryPoints" && (
                      <div id="tab-1" className="tab-content active">
                        <div className="stB01">
                          <h2>
                            1. Write story To
                            <span className="font-weight-bold">
                              Earn Points
                            </span>
                          </h2>
                          <h4>
                            Share Mowgli times to receive more airdrops. The
                            points will be converted to
                          </h4>
                          <h4 className="text-blue">
                            $MOWGLINFT at a later date.
                          </h4>

                          <div className="row my-md-5 my-3">
                            <div className="col-md-6">
                              <div className="st01">
                                <div className="st02">
                                  <img
                                    src={nft}
                                    className="img-fluid"
                                    alt="Mowgli"
                                  />
                                  <div className="stpoints">
                                    STORY PTS : 53453
                                  </div>
                                </div>

                                <div className="btn2 text-center">
                                  <a href="#">
                                    <button>Connect</button>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <!-- tab-1 closed --> */}
                    {/* <!-- tab-2 start --> */}
                    {tab === "SocialPoints" && (
                      <div id="tab-2" className="tab-content active">
                        <div className="stB01">
                          <h3 className="text-center my-4">
                            Total Social Points:
                            <span className="tp01">120</span>
                          </h3>
                          <div className="row">
                            <div className="col-lg-4 col-md-4">
                              <div className="bdr01 co2">
                                <h4>Referral</h4>
                                <h2>343</h2>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                              <div className="bdr02 co2">
                                <h4>Social</h4>
                                <h2>343</h2>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                              <div className="bdr03 co2">
                                <h4>Bonus</h4>
                                <h2>343</h2>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end flex-wrap my-4">
                            <div className="btn4">
                              <a href="#" className="bt01">
                                Assets
                              </a>
                              <a href="#" className="bt02">
                                Referral Link
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="stB01 my-4">
                          <h2>Tasks</h2>
                          <h5>Complete tasks to earn more social points.</h5>
                          <div className="next09 wrapper">
                            <div className="tab-wrapper mt-5">
                              <ul className="tabs">
                                <li
                                  onClick={() => setTaskTab("AllTasks")}
                                  data-tab="4"
                                  className={
                                    taskTab === "AllTasks"
                                      ? "tab-link active"
                                      : "tab-link"
                                  }
                                >
                                  All Tasks
                                </li>
                                <li
                                  onClick={() => setTaskTab("StoryTasks")}
                                  className={
                                    taskTab === "StoryTasks"
                                      ? "tab-link active"
                                      : "tab-link"
                                  }
                                  data-tab="5"
                                >
                                  Story Tasks
                                </li>
                                <li
                                  onClick={() => setTaskTab("XTasks")}
                                  className={
                                    taskTab === "XTasks"
                                      ? "tab-link active"
                                      : "tab-link"
                                  }
                                  data-tab="6"
                                >
                                  X Tasks
                                </li>
                                <li
                                  onClick={() => setTaskTab("OtherTasks")}
                                  className={
                                    taskTab === "OtherTasks"
                                      ? "tab-link active"
                                      : "tab-link"
                                  }
                                  data-tab="7"
                                >
                                  Other Tasks
                                </li>
                              </ul>
                            </div>

                            <div className="text-left content-wrapper">
                              {taskTab === "AllTasks" && (
                                <div id="tab-4" className="tab-content active">
                                  <div className="taskbarcover">
                                    <div className="tskbar">
                                      <div className="row align-items-center">
                                        <div className="col-md-2">
                                          <div className="tskl">
                                            <h5 className="font-weight-bold">
                                              +1000
                                            </h5>
                                            <span className="font-weight-bold">
                                              SOCIAL PTS
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-8">
                                          <h3>Connect Mowgli Rangers</h3>
                                          <p>
                                            Connect your Mowgli Rangers account
                                            to unlock more daily tasks and earn
                                            points.
                                          </p>
                                        </div>
                                        <div className="col-md-2">
                                          <a href="#" className="go">
                                            Go
                                          </a>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="tskbar">
                                      <div className="row align-items-center">
                                        <div className="col-md-2">
                                          <div className="tskl">
                                            <h5 className="font-weight-bold">
                                              +1000
                                            </h5>
                                            <span className="font-weight-bold">
                                              SOCIAL PTS
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-8">
                                          <h3>Connect Mowgli Rangers</h3>
                                          <p>
                                            Connect your Mowgli Rangers account
                                            to unlock more daily tasks and earn
                                            points.
                                          </p>
                                        </div>
                                        <div className="col-md-2">
                                          <a href="#" className="go">
                                            Go
                                          </a>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="tskbar">
                                      <div className="row align-items-center">
                                        <div className="col-md-2">
                                          <div className="tskl">
                                            <h5 className="font-weight-bold">
                                              +1000
                                            </h5>
                                            <span className="font-weight-bold">
                                              SOCIAL PTS
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-8">
                                          <h3>Connect Mowgli Rangers</h3>
                                          <p>
                                            Connect your Mowgli Rangers account
                                            to unlock more daily tasks and earn
                                            points.
                                          </p>
                                        </div>
                                        <div className="col-md-2">
                                          <a href="#" className="go">
                                            Go
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tskbar">
                                      <div className="row align-items-center">
                                        <div className="col-md-2">
                                          <div className="tskl">
                                            <h5 className="font-weight-bold">
                                              +1000
                                            </h5>
                                            <span className="font-weight-bold">
                                              SOCIAL PTS
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-8">
                                          <h3>Connect Mowgli Rangers</h3>
                                          <p>
                                            Connect your Mowgli Rangers account
                                            to unlock more daily tasks and earn
                                            points.
                                          </p>
                                        </div>
                                        <div className="col-md-2">
                                          <a href="#" className="go">
                                            Go
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {taskTab === "StoryTasks" && (
                                <div id="tab-5" className="tab-content active">
                                  <div className="taskbarcover">
                                    <div className="tskbar">
                                      <div className="row align-items-center">
                                        <div className="col-md-2">
                                          <div className="tskl">
                                            <h5 className="font-weight-bold">
                                              +1000
                                            </h5>
                                            <span className="font-weight-bold">
                                              SOCIAL PTS
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-8">
                                          <h3>Connect Mowgli Rangers</h3>
                                          <p>
                                            Connect your Mowgli Rangers account
                                            to unlock more daily tasks and earn
                                            points.
                                          </p>
                                        </div>
                                        <div className="col-md-2">
                                          <a href="#" className="go">
                                            Go
                                          </a>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="tskbar">
                                      <div className="row align-items-center">
                                        <div className="col-md-2">
                                          <div className="tskl">
                                            <h5 className="font-weight-bold">
                                              +1000
                                            </h5>
                                            <span className="font-weight-bold">
                                              SOCIAL PTS
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-8">
                                          <h3>Connect Mowgli Rangers</h3>
                                          <p>
                                            Connect your Mowgli Rangers account
                                            to unlock more daily tasks and earn
                                            points.
                                          </p>
                                        </div>
                                        <div className="col-md-2">
                                          <a href="#" className="go">
                                            Go
                                          </a>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="tskbar">
                                      <div className="row align-items-center">
                                        <div className="col-md-2">
                                          <div className="tskl">
                                            <h5 className="font-weight-bold">
                                              +1000
                                            </h5>
                                            <span className="font-weight-bold">
                                              SOCIAL PTS
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-8">
                                          <h3>Connect Mowgli Rangers</h3>
                                          <p>
                                            Connect your Mowgli Rangers account
                                            to unlock more daily tasks and earn
                                            points.
                                          </p>
                                        </div>
                                        <div className="col-md-2">
                                          <a href="#" className="go">
                                            Go
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {taskTab === "XTasks" && (
                                <div id="tab-6" className="tab-content active">
                                  <div className="taskbarcover">
                                    <div className="tskbar">
                                      <div className="row align-items-center">
                                        <div className="col-md-2">
                                          <div className="tskl">
                                            <h5 className="font-weight-bold">
                                              +1000
                                            </h5>
                                            <span className="font-weight-bold">
                                              SOCIAL PTS
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-8">
                                          <h3>Connect Mowgli Rangers</h3>
                                          <p>
                                            Connect your Mowgli Rangers account
                                            to unlock more daily tasks and earn
                                            points.
                                          </p>
                                        </div>
                                        <div className="col-md-2">
                                          <a href="#" className="go">
                                            Go
                                          </a>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="tskbar">
                                      <div className="row align-items-center">
                                        <div className="col-md-2">
                                          <div className="tskl">
                                            <h5 className="font-weight-bold">
                                              +1000
                                            </h5>
                                            <span className="font-weight-bold">
                                              SOCIAL PTS
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-8">
                                          <h3>Connect Mowgli Rangers</h3>
                                          <p>
                                            Connect your Mowgli Rangers account
                                            to unlock more daily tasks and earn
                                            points.
                                          </p>
                                        </div>
                                        <div className="col-md-2">
                                          <a href="#" className="go">
                                            Go
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {taskTab === "OtherTasks" && (
                                <div id="tab-7" className="tab-content active">
                                  {" "}
                                  <div className="taskbarcover">
                                    <div className="tskbar">
                                      <div className="row align-items-center">
                                        <div className="col-md-2">
                                          <div className="tskl">
                                            <h5 className="font-weight-bold">
                                              +1000
                                            </h5>
                                            <span className="font-weight-bold">
                                              SOCIAL PTS
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-8">
                                          <h3>Connect Mowgli Rangers</h3>
                                          <p>
                                            Connect your Mowgli Rangers account
                                            to unlock more daily tasks and earn
                                            points.
                                          </p>
                                        </div>
                                        <div className="col-md-2">
                                          <a href="#" className="go">
                                            Go
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <!-- tab-2 closed --> */}
                    {/* <!-- tab-3 start --> */}
                    {tab === "MowgliPass" && (
                      <div id="tab-3" className="tab-content active">
                        <div className="stB01">
                          <div className="d-flex justify-content-between flex-wrap">
                            <div>
                              <h3>
                                Redeem{" "}
                                <span className="font-weight-bold">
                                  Mowgli Pass
                                </span>
                              </h3>
                            </div>
                            <div>
                              <h3>Qty: 0</h3>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <h5>
                                <span className="text-blue font-weight-bold">
                                  Mowgli Pass
                                </span>{" "}
                                Will Be Calculated As Airdrops.
                              </h5>
                              <div className="mt-md-5 mt-3">
                                <h5>How To Get Mowgli Pass?</h5>
                                <ul className="mowgPass">
                                  <li>Participate in Community Events</li>
                                  <li>Given By Various Different Events</li>
                                  <li>Be Active On Twitter</li>
                                </ul>
                                <p className="adtext  ">Add Mowgli Pass</p>
                                <div className="addpass ">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="INPUT CODE"
                                  />
                                </div>
                                <div>
                                  <input
                                    type="submit"
                                    className="submitbtn mt-4"
                                    value="SUBMIT"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <img
                                src={pass}
                                className="img-fluid"
                                alt="Mowgli Pass"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <!-- tab-3 closed --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <footer className="gr01" id="footer">
        <div className="text-white footer-newsletter1">
          <div className="container">
            <div className="row mt-4">
              <div className="col-lg-7">
                <div className="ftrlogo d-flex justify-content-around flex-wrap">
                  <a href="#">Staking</a>
                  <a href="#">Create Story</a>
                  <a href="#">Events</a>
                  <a href="#">Games</a>
                </div>
              </div>
              <div className="col-lg-5 text-md-right text-center">
                <div className="ftrsocial">
                  <a href="#">
                    <img src={x} alt="Instagram" />
                  </a>
                  <a href="#">
                    <img src={insta} alt="Instagram" />
                  </a>
                  <a href="#">
                    <img src={telegram} alt="Instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container footer-bottom clearfix">
          <div className="copyright text-center">
            &copy; Jungle Quest Challenge
          </div>
        </div>
      </footer>
    </div>
  );
}
