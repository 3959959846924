import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationData from "../Images/image.json";
import aido from "../Images/siran.mp3";

export default function Page404() {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      {/* <audio controls>
        <source autoplay src={aido} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <button onClick={togglePlay}>{isPlaying ? "Pause" : "Play"}</button> */}
      <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
    </div>
  );
}
