import React from "react";
import img from "../../Images/ms-token.png";
import video from "../../video/videobg.mp4";
import video2 from "../../video/videobg2.mp4";
import mowgli_sanctuary_logo from "../../Images/mowgli-sanctuary-logo.png";
import community from "../../Images/community.png";
import world from "../../Images/world.png";
import task from "../../Images/task.png";
import coins from "../../Images/coins.png";
import dailytask from "../../Images/dailytask.png";
import weeklytask from "../../Images/weeklytask.png";
import monthlytask from "../../Images/monthlytask.png";
import reffral from "../../Images/reffral.png";
import token_mowgli from "../../Images/token-mowgli.png";
import token_sherekhan from "../../Images/token-sherekhan.png";
import token_baloo from "../../Images/token-baloo.png";
import token_bagheera from "../../Images/token-bagheera.png";
import token_ka from "../../Images/token-ka.png";
import token_kingLouie from "../../Images/token-kingLouie.png";
import ms_token from "../../Images/ms-token.png";
import clint1 from "../../Images/clients/client-1.png";
import clint12 from "../../Images/clients/client-2.png";
import clint3 from "../../Images/clients/client-3.png";
import clint4 from "../../Images/clients/client-4.png";
import clint5 from "../../Images/clients/client-5.png";
import clint6 from "../../Images/clients/client-6.png";
import x from "../../Images/x.png";
import insta from "../../Images/insta.png";
import telegram from "../../Images/telegram.png";
import d from "../../Images/moresharebg.jpg";
import CreateButoon from "../ConnectButton";
import { Carousel } from "react-responsive-carousel";
import ConnectButton1 from "../ConnectButton1";

export default function Home() {
  return (
    <div>
      {/* <!-- ======= Header ======= --> */}
      <header
        id="header"
        className="fixed-top bg-dark  "
        style={{ "--bs-bg-opacity": 0.5 }}
      >
        <div className="container d-flex align-items-center">
          <div className="col-sm-2">
            <a href="#" className="logo mr-auto">
              <img
                src={mowgli_sanctuary_logo}
                alt="Jungle Quest Challenge"
                className="img-fluid w-full "
              />
            </a>
          </div>
          <div className="col-sm-8"></div>
          <div className="col-sm-2">
            {" "}
            <CreateButoon />
          </div>

          {/* <a href="#" className="get-started-btn scrollto">
            Join Now
          </a> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      {/* <!-- ======= Hero Section ======= --> */}
      <section className="pt-0 outter option1">
        <section className="video-container">
          <video
            src={video}
            autoPlay
            loop
            preload="auto"
            playsInline
            muted
          ></video>
          <div className="callout">
            <div className="text-center mstoken">
              <img
                src={img}
                className="vert-move"
                alt="Jungle Quest Challenge"
              />
            </div>
            <h1>Join the Jungle Quest Social Challenge!</h1>
            <h4 className="desc">
              Embark on a social adventure and earn rewards! Engage with our
              community, spread the word about our project, and complete daily
              tasks to earn valuable points.
            </h4>
            <ConnectButton1 />
            {/* <div className="button">
              <div className="inner">Connect With Wallet</div>
            </div> */}
          </div>
        </section>
      </section>
      {/* <!-- End Hero --> */}

      {/* <!-- ======= About Us Section ======= --> */}
      {/* <!-- End About Us Section --> */}

      {/* <!-- ======= Why Us Section ======= --> */}
      <section
        // style={{ background: "rgba(29, 17, 61, 0.8)" }}
        className="sctn why-us getBox1"
      >
        <div className="container">
          <div className="row pb-5">
            <div className="col-lg-10 justify-content-center">
              <div className="content">
                <h3 className="text-white">How to Participate in</h3>
                <h2 className="font-weight-bold">Jungle Quest Challenge</h2>
              </div>

              <div className="row mt-5">
                <div className="col-lg-4 col-md-2">
                  <div className="boxcomn gr01">
                    <span className="iconss">
                      <img src={community} alt="" />
                    </span>
                    <h4>Engage with the Community</h4>
                    <p>
                      Like, comment, and share posts from our community. The
                      more you engage, the more points you earn.
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 col-md-2">
                  <div className="gr02 boxcomn">
                    <span className="iconss">
                      <img src={world} alt="" />
                    </span>
                    <h4>
                      Spread the <br />
                      Word
                    </h4>
                    <p>
                      Share our project with your friends and followers. Each
                      new referral earns you bonus points!
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 col-md-2">
                  <div className="gr03 boxcomn">
                    <span className="iconss">
                      <img src={task} alt="" />
                    </span>
                    <h4>
                      Complete <br />
                      Daily Tasks
                    </h4>
                    <p>
                      Participate in daily tasks, such as sharing a project
                      update or tagging a friend, to earn extra points.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Why Us Section --> */}

      {/* <!-- ======= Skills Section ======= --> */}
      <section
        style={{
          backgroundImage: `url(${d})`,
        }}
        className="skills"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-8 pt-4 pt-lg-0 gtext">
              <div className="boxcomn text-center getBox">
                <h3 className="text-white">
                  The more you share, the more you earn.
                </h3>
                <h2 className="bgcoins">
                  Get 1000 MS Coins{" "}
                  <span className="mscoins">
                    <img src={coins} alt="coins" />
                  </span>
                </h2>

                <p className="text-white">
                  Share the story with your friends on your favorite social
                  media platform and instantly earn 1000 points!{" "}
                </p>
                <p className="text-white">
                  Spread the word and earn rewards effortlessly.
                </p>
                <h4 className="text-white font-weight-bold">
                  Start sharing now and watch your points grow!
                </h4>
                <div className="button2">
                  <div className="inner">Share Now</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="stry009">
        <div className="container mt-5">
          <div className="content text-center text-white mb-5">
            <h3>Achieve your goals by completion of</h3>
            <h2 className="font-weight-bold">Our Task</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-2">
              <h3 className="text-white text-center mb-4">Daily Taks</h3>
              <div className="blog_content">
                <div className="owl-carousel owl-theme">
                  <div className="blog_item">
                    <div className="blog_image">
                      <img className="img-fluid" src={dailytask} alt="" />
                      <span>
                        <i className="icon ion-md-create">1</i>
                      </span>
                    </div>
                    <div className="blog_details">
                      <div className="blog_title">
                        <div className="button text-center">
                          <a href="#" className="text-white">
                            Participate Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blog_item">
                    <div className="blog_image">
                      <img className="img-fluid" src={dailytask} alt="" />
                      <span>
                        <i className="icon ion-md-create">2</i>
                      </span>
                    </div>
                    <div className="blog_details">
                      <div className="blog_title">
                        <div className="button text-center">
                          <a href="#" className="text-white">
                            Participate Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-2">
              <h3 className="text-white text-center mb-4">Weekly Taks</h3>
              <div className="blog_content">
                <div className="owl-carousel owl-theme">
                  <div className="blog_item">
                    <div className="blog_image">
                      <img
                        className="img-fluid"
                        src={weeklytask}
                        alt="Jungle Quest Challenge"
                      />
                      <span>
                        <i className="icon ion-md-create">1</i>
                      </span>
                    </div>
                    <div className="blog_details">
                      <div className="blog_title">
                        <div className="button text-center">
                          <a href="#" className="text-white">
                            Participate Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blog_item">
                    <div className="blog_image">
                      <img
                        className="img-fluid"
                        src={weeklytask}
                        alt="Jungle Quest Challenge"
                      />
                      <span>
                        <i className="icon ion-md-create">2</i>
                      </span>
                    </div>
                    <div className="blog_details">
                      <div className="blog_title">
                        <div className="button text-center">
                          <a href="#" className="text-white">
                            Participate Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-2">
              <h3 className="text-white text-center mb-4">Monthly Taks</h3>
              <div className="blog_content">
                <div className="owl-carousel owl-theme">
                  <div className="blog_item">
                    <div className="blog_image">
                      <img
                        className="img-fluid"
                        src={monthlytask}
                        alt="Jungle Quest Challenge"
                      />
                      <span>
                        <i className="icon ion-md-create">1</i>
                      </span>
                    </div>
                    <div className="blog_details">
                      <div className="blog_title">
                        <div className="button text-center">
                          <a href="#" className="text-white">
                            Participate Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blog_item">
                    <div className="blog_image">
                      <img
                        className="img-fluid"
                        src={monthlytask}
                        alt="Jungle Quest Challenge"
                      />
                      <span>
                        <i className="icon ion-md-create">2</i>
                      </span>
                    </div>
                    <div className="blog_details">
                      <div className="blog_title">
                        <div className="button text-center">
                          <a href="#" className="text-white">
                            Participate Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shareS09">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <img
                  src={reffral}
                  className="vert-move img-fluid"
                  alt="Jungle Quest Challenge"
                />
              </div>
              <div className="col-lg-7 offset-lg-1">
                <div className="boxcomn gr01 text-center">
                  <h3 className="text-white">
                    Invite your friends to join the fun using your referral code
                    and instantly receive{" "}
                  </h3>
                  <h1 className="text-white bgcoins my-3">
                    1200 coins!{" "}
                    <span className="mscoins">
                      <img src={coins} alt="coins" />
                    </span>
                  </h1>
                  <h4 className="text-white">
                    Share your code, earn rewards, and let the adventure begin!
                  </h4>
                  <div className="button2 my-3">
                    <a href="#">Share Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="crtstory getBox1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="content text-center text-white mb-5">
                <h3>Introducing the Story Ideas</h3>
                <h2 className="font-weight-bold">Story Templates</h2>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="blog_content">
                <div className="owl-carousel owl-theme">
                  <Carousel
                    autoPlay
                    showStatus={false}
                    showThumbs={false}
                    showArrows={false}
                  >
                    <div className="blog_item">
                      <div className="popBox">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="d-flex flex-column">
                              <div className="position-relative">
                                <img
                                  src={token_mowgli}
                                  className="imgglow img-fluid"
                                  alt=""
                                />
                                <span className="addnft">
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop"
                                  >
                                    <i className="fa fa-plus-circle"></i>
                                  </a>
                                </span>
                              </div>

                              <div className="mt-105">
                                <div className="row justify-content-center nft09">
                                  <div className="col-4">
                                    <img
                                      src={token_sherekhan}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-4">
                                    <img
                                      src={token_baloo}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="col-4">
                                    <img
                                      src={token_bagheera}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="col-4">
                                    <img
                                      src={token_ka}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="col-4">
                                    <img
                                      src={token_kingLouie}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8 offset-md-1">
                            <div className="stbg09">
                              <h4 className="text-white">My Story</h4>
                              <p className="text-white">
                                Once upon a time, in the heart of the lush and
                                vibrant Indian jungle, there lived a young boy
                                named Mowgli. Abandoned as a baby and taken in
                                by a pack of wolves, Mowgli grew up under the
                                care of Mother Wolf and her cubs. From them, he
                                learned the ways of the jungle – how to run with
                                the wind, climb trees with agility, and
                                communicate with the animals through a language
                                of whispers and gestures.
                              </p>
                              <p className="text-white">
                                As Mowgli grew older, he became friends with
                                many of the jungle's inhabitants. There was
                                Bagheera, the sleek and cunning black panther
                                who watched over him like a guardian; Baloo, the
                                wise and jovial bear who taught him the Laws of
                                the Jungle; and Kaa, the ancient python who
                                shared his wisdom in the serpentine coils of his
                                hypnotic embrace.
                              </p>
                              {/* <p className="text-white">
                                But not all creatures in the jungle were
                                friendly. Shere Khan, the fierce and vengeful
                                tiger, harbored a deep hatred for humans and
                                sought to rid the jungle of Mowgli's presence.
                                With his blazing eyes and razor-sharp claws,
                                Shere Khan was a constant threat, lurking in the
                                shadows and plotting his next move.
                              </p> */}
                              <div className="mb-10 d-flex flex-wrap align-items-center">
                                <div>
                                  {" "}
                                  <div className="button3 text-white mb-2">
                                    <a href="#">Get This Template</a>{" "}
                                  </div>
                                </div>
                                <div className="mb-2 text-white mx-2"> Or</div>
                                <div>
                                  {" "}
                                  <div className="button3 text-white mb-2">
                                    <a href="#">Create Your Own</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="blog_item">
                      <div className="popBox">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="d-flex flex-column">
                              <div className="position-relative">
                                <img
                                  src={token_mowgli}
                                  className="imgglow img-fluid"
                                  alt=""
                                />
                                <span className="addnft">
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop"
                                  >
                                    <i className="fa fa-plus-circle"></i>
                                  </a>
                                </span>
                              </div>

                              <div className="mt-105">
                                <div className="row justify-content-center item-content-center nft09">
                                  <div className="col-4">
                                    <img
                                      src={token_sherekhan}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-4">
                                    <img
                                      src={token_baloo}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="col-4">
                                    <img
                                      src={token_bagheera}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="col-4">
                                    <img
                                      src={token_ka}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="col-4">
                                    <img
                                      src={token_kingLouie}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8 offset-md-1">
                            <div className="stbg09">
                              <h4 className="text-white">My Story</h4>
                              <p className="text-white">
                                Once upon a time, in the heart of the lush and
                                vibrant Indian jungle, there lived a young boy
                                named Mowgli. Abandoned as a baby and taken in
                                by a pack of wolves, Mowgli grew up under the
                                care of Mother Wolf and her cubs. From them, he
                                learned the ways of the jungle – how to run with
                                the wind, climb trees with agility, and
                                communicate with the animals through a language
                                of whispers and gestures.
                              </p>
                              <p className="text-white">
                                As Mowgli grew older, he became friends with
                                many of the jungle's inhabitants. There was
                                Bagheera, the sleek and cunning black panther
                                who watched over him like a guardian; Baloo, the
                                wise and jovial bear who taught him the Laws of
                                the Jungle; and Kaa, the ancient python who
                                shared his wisdom in the serpentine coils of his
                                hypnotic embrace.
                              </p>
                              {/* <p className="text-white">
                                But not all creatures in the jungle were
                                friendly. Shere Khan, the fierce and vengeful
                                tiger, harbored a deep hatred for humans and
                                sought to rid the jungle of Mowgli's presence.
                                With his blazing eyes and razor-sharp claws,
                                Shere Khan was a constant threat, lurking in the
                                shadows and plotting his next move.
                              </p> */}
                              <div className="mb-10 d-flex flex-wrap align-items-center">
                                <div>
                                  {" "}
                                  <div className="button3 text-white mb-2">
                                    <a href="#">Get This Template</a>{" "}
                                  </div>
                                </div>
                                <div className="mb-2 text-white mx-2"> Or</div>
                                <div>
                                  {" "}
                                  <div className="button3 text-white mb-2">
                                    <a href="#">Create Your Own</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Skills Section --> */}

      {/* <!-- ======= Services Section ======= --> */}
      <section>
        <section className="dist009 video-container2">
          {/* <video src={video2} autoplay loop playsinline muted></video> */}
          <video
            src={video2}
            autoPlay
            loop
            preload="auto"
            playsInline
            muted
          ></video>
          <div className="callout">
            <div className="row align-items-center">
              <div className="col-md-5">
                {" "}
                <div className="text-center mstoken2">
                  <img
                    src={ms_token}
                    className="img-fluid vert-move"
                    alt="Jungle Quest Challenge"
                  />
                </div>
              </div>
              <div className="col-md-6 offset-md-1">
                <h1>10,000,000 Token</h1>
                <h3 className="text-white">Total Distribution for Users</h3>
                <h4 className="desc">
                  Embark on a social adventure and earn rewards! Engage with our
                  community, spread the word about our project, and complete
                  daily tasks to earn valuable points.
                </h4>
                <div className="button">
                  <div className="inner">Own Your Token</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="cliens p-5 getBox1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              {" "}
              <div className="content my-5">
                <h2 className="font-weight-bold text-white">
                  Venture Capitalists & Investors
                </h2>
              </div>
            </div>
          </div>

          <div className="row cli009 aos-init aos-animate">
            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={clint1}
                className="img-fluid"
                alt="Jungle Quest Challenge"
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={clint12}
                className="img-fluid"
                alt="Jungle Quest Challenge"
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={clint3}
                className="img-fluid"
                alt="Jungle Quest Challenge"
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={clint4}
                className="img-fluid"
                alt="Jungle Quest Challenge"
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={clint5}
                className="img-fluid"
                alt="Jungle Quest Challenge"
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={clint6}
                className="img-fluid"
                alt="Jungle Quest Challenge"
              />
            </div>
          </div>
          <div className="row mt-md-5 justify-content-center">
            <div className="col-lg-6">
              <div className="gr02 boxcomn">
                {" "}
                <h2 className="text-white">Stay tuned for more info</h2>
                <hr />
                <h4 className="text-white">
                  Keep an eye out for updates and announcements coming your way
                  soon.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Contact Section --> */}

      {/* <!-- ======= Footer ======= --> */}
      <footer className="gr01" id="footer">
        <div className="text-white footer-newsletter">
          <div className="container">
            <div className="row ">
              <div className="col-lg-7">
                <div className="ftrlogo d-flex justify-content-around flex-wrap">
                  <a href="#">Staking</a>
                  <a href="#">Create Story</a>
                  <a href="#">Events</a>
                  <a href="#">Games</a>
                </div>
              </div>
              <div className="col-lg-5 text-md-right text-center">
                <div className="ftrsocial">
                  <a href="#">
                    <img src={x} alt="Instagram" />
                  </a>
                  <a href="#">
                    <img src={insta} alt="Instagram" />
                  </a>
                  <a href="#">
                    <img src={telegram} alt="Instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container footer-bottom clearfix">
          <div className="copyright text-center text-white">
            &copy; Jungle Quest Challenge
          </div>
        </div>
      </footer>
      {/* <!-- End Footer --> */}

      <a href="#" className="back-to-top">
        <i className="ri-arrow-up-line"></i>
      </a>
      {/* <div id="preloader"></div> */}

      {/* <!-- Vendor JS Files --> */}
      {/* <script src="assets/vendor/jquery/jquery.min.js"></script>
  <script src="assets/js/main.js"></script>
  <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script type="text/javascript" src="assets/js/owl.carousel.js"></script>
  <script>$('.owl-carousel').owlCarousel({
        loop:true,
        margin:10,
        dots:false,
        nav:true,
        autoplay:true,   
        smartSpeed: 3000, 
        autoplayTimeout:7000,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
    })</script> */}
    </div>
  );
}
