import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getDefaultWallets } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  zora,
  zkSyncSepoliaTestnet,
  bscTestnet,
} from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

// import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
const { chains, publicClient } = configureChains(
  [
    mainnet,
    polygon,
    optimism,
    arbitrum,
    base,
    zora,
    bscTestnet,
    zkSyncSepoliaTestnet,
  ],
  [publicProvider()]
);
// const projectId = "0345c7230f52356034d20c7c5fbf2519";
const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  projectId: "0345c7230f52356034d20c7c5fbf2519",
  chains,
});

const metadata = {
  name: "puremint",
  description: "Welcome to PureMint, It is future of innovation and finance",
  url: "https://puremint.io/",
  icons: ["https://puremint.io/logo/Puremint-logo.png"],
};
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  metadata,
});
// const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <App />
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
