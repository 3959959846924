import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@rainbow-me/rainbowkit/styles.css";
import { configureChains, mainnet, useAccount, useDisconnect } from "wagmi";
import {
  arbitrum,
  base,
  optimism,
  polygon,
  zora,
  zkSyncSepoliaTestnet,
  bscTestnet,
  sepolia,
} from "viem/chains";
import { publicProvider } from "wagmi/providers/public";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "aos/dist/aos.css";
import Aos from "aos";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Component/Home";
import Dashboard from "./Component/Dashboard";
import Page404 from "./Component/Page404";

function App() {
  Aos.init();
  const { chains, publicClient } = configureChains(
    [
      mainnet,
      polygon,
      optimism,
      arbitrum,
      base,
      zora,
      zkSyncSepoliaTestnet,
      bscTestnet,
      sepolia,
    ],
    [publicProvider()]
  );

  return (
    <div>
      <RainbowKitProvider chains={chains}>
        <BrowserRouter>
          {/* <CreateButoon /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </RainbowKitProvider>
    </div>
  );
}

export default App;
